import React from 'react';
import cn from 'classnames';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { OneColumn } from '../../components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';
import styles from './order.module.scss';

export function Head() {
  return (
    <>
      <SEO
        title="Заказ подтвержден"
        description="Ваш заказ подтвержден и готов к печати. Мы сообщим вам как только заказ будет передан в
    печать/доставку."
      />
      <YandexTableau />
    </>
  );
}

function OrderApprovedPage() {
  return (
    <Layout>
      <OneColumn>
        <div className={styles.block}>
          <h1 className={cn({ [styles.header]: true, [styles.success]: true })}>
            Заказ подтвержден
          </h1>
          <p className={styles.description}>
            Мы взяли ваш заказ в работу. Мы отправляем заказы в печать в 10:00 каждый будний день.
            Как только заказ будет передан в печать, мы сообщим вам отдельным письмом 😘
          </p>
        </div>
      </OneColumn>
    </Layout>
  );
}

export default OrderApprovedPage;
